import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { SiteViews } from '../../models/enums/siteViews';
import onNavigate from '../../modules/onNavigate';

import accentImage from '../../images/shaped_burner_img.jpg';

const YShapedBurner: React.FC = () => {
  let navigate = useNavigate();

  return (
    <Container className="page-container">
      <Row>
        <Col xs={12}>
          <h3>Fabricated Stainless Steel Y Shaped Burner for the Residential Fireplace Industry</h3>
          <p>
            <img
              src={accentImage}
              alt="Fabricated Stainless Steel Y Shaped Burner"
              style={{ marginRight: 12 }}
              className="float-start img-fluid"
            />
            The project highlighted here was designed for use as a gas fireplace burner and features
            many complex design elements and fabrication processes. Formed from 1.00" diameter, 304
            stainless steel tubing, this burner incorporated complicated geometric bends and
            required extensive porting to maximize flow. With over 50 years of experience as a
            custom manufacturer Master Manufacturing Group is able to pull from a vast knowledge base of process and
            industry knowledge in projects like this and provide our customers with innovative
            solutions and value added services.
          </p>
          <p>
            The components for this Y shaped burner were precision formed on our CNC bender and
            utilized plasma cutting and a punch press to complete the forming process. In addition,
            end coining was also applied as well as the application of T-notch end and venture
            smash, with all parts being joined using MIG welding. Gas flow was a major consideration
            with this product and required extensive porting to reach the correct criteria. We
            employ proprietary CNC porting equipment which allowed us to provide this customer with
            a process that met their exact requirements cost effectively.
          </p>
          <p>
            For more information on this project or to learn more about the processes that Woodmark
            employed to manufacture it,&nbsp;
            <span className="fake-link" onClick={() => navigate(onNavigate(`#${SiteViews.Contact}`))}>
              contact us
            </span>
            &nbsp; directly.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant="primary" onClick={() => navigate(onNavigate(`#${SiteViews.Portfolio}`))}>
            View Other Portfolio
          </Button>
        </Col>
        <Col>
          <Button
            variant="primary"
            className="float-end"
            onClick={() => navigate(onNavigate(`#${SiteViews.Quote}`))}
          >
            Request Quote
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4 className="project-header">Project Highlights:</h4>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p>
            <strong>Product Description</strong> This Y shaped burner is used in a fireplace.
          </p>
          <div style={{ marginBottom: '1rem' }}>
            <div>
              <strong>Precision Fabrication Capabilities Applied/Processes</strong>
            </div>
            <div className="float-end" style={{ paddingRight: 36 }}>
              Secondary: <br />
              Venturi Smash <br />
              T-Notch
            </div>
            <div>
              Primary: <br />
              Fabrication <br />
              Shearing <br />
              Porting <br />
              Plasma Cutting <br />
              Bending <br />
              End Coining <br />
              MIG Welding
            </div>
          </div>
          <p>
            <strong>Equipment Used to Manufacture Part</strong> <br />
            Tube Shear, Proprietary CNC Porting Machines, Automatic Plasma Cutter, CNC Bender, Punch
            Press, MIG Welder
          </p>
          <p>
            <strong>Overall Part Dimensions</strong> <br />
            Length: 18" Width: 5" Height: 3"
          </p>
        </Col>
        <Col md={6}>
          <p>
            <strong>Tightest Tolerances</strong> <br />± 0.003" on Ports
          </p>
          <p>
            <strong>Material Used</strong> <br />
            304 Stainless Steel Tubing O.D.: Ø 1.00"
          </p>
          <p>
            <strong>Material Finish</strong> <br />
            Raw/Machined
          </p>
          <p>
            <strong>Industry for Use</strong> <br />
            Residential Fireplace Industry
          </p>
          <p>
            <strong>Standards Met</strong> <br />
            Customer Specifications, 2D CAD Drawing
          </p>
          <p>
            <strong>Product Name</strong> <br />Y Fireplace Burner
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default YShapedBurner;
