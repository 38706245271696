import { faEnvelope, faMapMarkedAlt, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import MMGMap from './mmgMap';

const Contact: React.FC = () => {
  return (
    <Container className="page-container">
      <Row>
        <Col xs={12}>
          <h3>Contact Master Manufacturing Group</h3>
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <div className="mr-2" style={{ minHeight: 400 }}>
            <MMGMap />
          </div>
        </Col>
        <Col sm={4}>
          <p>
            <FontAwesomeIcon icon={faMapMarkedAlt} />
            &nbsp;<strong>Master Manufacturing Group</strong>
            <br />
            220 Masters Way
            <br />
            Dayton, TN  37321
          </p>
          <p>
            <FontAwesomeIcon icon={faMobileAlt} />
            &nbsp; Phone: <a href="tel:4234287810">423.428.7810</a>
            <br />
            <FontAwesomeIcon icon={faEnvelope} />
            &nbsp; Email: <a href="mailto:frontdesk@mastermfggroup.com">Sales</a> or{' '}
            <a href="mailto:frontdesk@mastermfggroup.com">Jobs</a>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
