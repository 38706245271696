import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';

import griesemer from '../../images/team/eric.griesemer.jpg';
import leeseberg from '../../images/team/tony.leeseburg.jpg';
import lewis from '../../images/team/brian.lewis.jpg';

const About : React.FC = () => {

    return (
        <Container className="page-container">
            <Row>
                <Col xs={12}>
                    <h3>Master Manufacturing Group Team</h3>
                </Col>
            </Row>
            <Row>
                <Col md={6} xs={12}>
                <p><img src={griesemer} alt="Eric Griesemer" className="img-fluid float-end ps-2" /><strong>Eric Griesemer<br />President/CEO</strong><br />Eric began his career in the appliance industry in 1984 working with Roper Corporation, and eventually GE Appliances after their acquisition of Roper.  Eric moved into the component supply base of the gas appliance industry in 1989 serving as Sales Engineer for Burner Systems International, Inc. (“BSI”).  Mr. Griesemer served many roles at BSI during his 28 years of service with the last role being CEO/President from 2007 to 2016.  The company was sold to a strategic competitor in June 2016.   Eric and his wife, Jennifer, purchased Woodmack Products, Inc. (“WPI”) in 2017, operating the business in Rancho Cordova, CA, until mid-2020.  In 2020, the company relocated to Dayton, TN, and is now doing business as MASTER MANUFACTURING GROUP.</p>
                </Col>
                <Col md={6} xs={12}>
                <p><img src={leeseberg} alt="Tony Leeseberg" className="img-fluid float-end ps-2" /><strong>Tony Leeseberg<br />Engineering Manager</strong><br />Tony started his career in the gas appliance industry in 1992 at Burner Systems International (BSI) as a laboratory technician. Tony continued his education at the University of Tennessee and graduated in 1999 with a Bachelor of Science degree in Mechanical Engineering. Tony advanced in serving BSI as Product Development Engineer (2000) and Product Development Engineering Manager (2012). Tony moved to the Chicago area in 2017 after BSI was acquired by Robertshaw Corporation. In May 2020, Tony joined WPI in CA prior to the relocation to Dayton, TN. Moving to Dayton, TN, in July 2020, Tony now invests his talents and experience with MASTER MANUFACTURING GROUP.</p>
                </Col>
            </Row>
            <Row>
                <Col md={6} xs={12}>
                <p><img src={lewis} alt="Brian Lewis" className="img-fluid float-end ps-2" /><strong>Brian Lewis<br />Quality Manager</strong><br />Brian is a Quality Manager with 27 years of experience working alongside and learning from some of the most talented associates in the appliance industry.  Brian began his career in 1996 at Maytag Appliance (now Whirlpool Corporation) in Cleveland, Tennessee, as a production associate / line leader.  Brian made his transition from production to quality in 2003 when he joined the Harper-Wyman team after the company relocated their facility from Princeton, Illinois, to Chattanooga, Tennessee.  Harper-Wyman was subsequently purchased by Burner Systems International in 2005.  Brian worked with BSI, serving as a Quality Engineer and ultimately as Quality Manger in 2013.  In 2016, when Robertshaw purchased BSI, Brian continued his role as Quality Manager and helped the team facilitate the Chattanooga plant closure and relocation of the Chattanooga processes to two primary Robertshaw facilities in Mexico.  Within Robertshaw, Brian worked as Quality Manager for the Commercial Business Unit supporting production primarily in the Celaya, Nuevo Laredo, and Matamoros facilities.  In October 2020, Brian joined MASTER MANUFACTURING GROUP as Quality Manager.</p>
                </Col>
                <Col md={6} xs={12}>
                <p></p>
                </Col>
            </Row>
        </Container>
        
    );

}

export default About;
