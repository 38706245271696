import React from 'react';
import { Button, Card, CardImg, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import onNavigate from '../../modules/onNavigate';
import ringBurner from '../../images/portfolio_img1.jpg';
import steelBurner from '../../images/image001.jpg';
import yShapedBurner from '../../images/portfolio_img3.jpg';
import doubleWalledManifold from '../../images/portfolio_img4.jpg';
import uShapedBurder from '../../images/portfolio_img5.jpg';
import aluminumTube from '../../images/portfolio_img6.jpg';
import { SiteViews } from '../../models/enums/siteViews';

const Portfolio: React.FC = () => {
  let navigate = useNavigate();

  return (
    <Container className="page-container">
      <Row>
        <Col xs={12}>
          <h3>Portfolio of Past Projects</h3>
          <p>
            Master Manufacturing Group provides our customers with innovative solutions and value added
            services that have made us an industry leader. Here are just a few examples of the
            quality and craftsmanship that we deliver every day.
          </p>
          <Row>
            <Col md={4} className="mb-4">
              <Card className="h-100">
                <Card.Img width="100%" src={ringBurner} alt="Ring Burner" />
                <Card.Body>
                  <Card.Title>Ring Burner</Card.Title>
                  <Card.Subtitle>Industrial Cooking</Card.Subtitle>
                  <Card.Text>
                    Fabrication of a Stainless Steel Ring Burner for the Industrial Cooking Industry
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Button
                    style={{ width: '100%' }}
                    onClick={() => navigate(onNavigate(`#${SiteViews.MetalFormingStainlessSteelTube}`))}
                  >
                    Learn More
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100">
                <CardImg width="100%" src={steelBurner} alt="Steel Burner" />
                <Card.Body>
                  <Card.Title>Steel Burner</Card.Title>
                  <Card.Subtitle>Outdoor Grilling</Card.Subtitle>
                  <Card.Text>
                    Fabricated Stainless Steel Burner for the Outdoor Grill Industry
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Button
                    style={{ width: '100%' }}
                    onClick={() =>
                      navigate(onNavigate(`#${SiteViews.CustomFabricationStainlessSteelBurner}`))
                    }
                  >
                    Learn More
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100">
                <CardImg width="100%" src={yShapedBurner} alt="Y Shaped Burner" />
                <Card.Body>
                  <Card.Title>Y Shaped Burner</Card.Title>
                  <Card.Subtitle>Residential Fireplace</Card.Subtitle>
                  <Card.Text>
                    Fabricated Stainless Steel Y Shaped Burner for the Residential Fireplace
                    Industry
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Button
                    style={{ width: '100%' }}
                    onClick={() =>
                      navigate(onNavigate(`#${SiteViews.PrecisionFabricationYShapedStainlessSteelBurner}`))
                    }
                  >
                    Learn More
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100">
                <CardImg width="100%" src={doubleWalledManifold} alt="Double Walled Manifold" />
                <Card.Body>
                  <Card.Title>Double Walled Manifold</Card.Title>
                  <Card.Subtitle>Commercial Boiler</Card.Subtitle>
                  <Card.Text>
                    Fabrication of a Double Wall Manifold for the Commercial Boiler Industry
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Button
                    style={{ width: '100%' }}
                    onClick={() => navigate(onNavigate(`#${SiteViews.CustomFabricatedDoubleWallManifold}`))}
                  >
                    Learn More
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100">
                <CardImg width="100%" src={uShapedBurder} alt="U-Shaped Commercial Burner" />
                <Card.Body>
                  <Card.Title>U Shaped Burner</Card.Title>
                  <Card.Subtitle>Commercial Cooking</Card.Subtitle>
                  <Card.Text>
                    Fabrication of a U-Shaped Commercial Burner for the Commercial Cooking Industry
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Button
                    style={{ width: '100%' }}
                    onClick={() => navigate(onNavigate(`#${SiteViews.MetalFabricationCommercialBurner}`))}
                  >
                    Learn More
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100">
                <CardImg width="100%" src={aluminumTube} alt="Aluminum Fireplace Connector Tube" />
                <Card.Body>
                  <Card.Title>Aluminum Connecture Tube</Card.Title>
                  <Card.Subtitle>Residential Fireplace</Card.Subtitle>
                  <Card.Text>
                    Fabrication and Assembly of an Aluminum Fireplace Connector Tube for the
                    Residential Fireplace Industry
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Button
                    style={{ width: '100%' }}
                    onClick={() =>
                      navigate(onNavigate(`#${SiteViews.CustomFabricatedAluminumFireplaceConnectorTube}`))
                    }
                  >
                    Learn More
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Portfolio;
