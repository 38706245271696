import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import logo from '../images/logo.png';
import { SiteViews } from '../models/enums/siteViews';

const Header: React.FC = () => {
  let navigate = useNavigate();

  const onNavigateHome = (event: React.SyntheticEvent) => {
    event.preventDefault();
    navigate('/');
  };

  const onNavigateQuote = (event: React.SyntheticEvent) => {
    event.preventDefault();
    navigate(SiteViews.Quote);
  };

  const onNavLinkNavigate = (eventKey: any) => {
    console.log(eventKey);
    switch (eventKey) {
      case '#home':
        navigate('/');
        break;
      default:
        const target = eventKey.replace('#', '/');
        navigate(target);
        break;
    }
  };

  return (
    <header id="wm-header">
      <Navbar variant="light" bg="#FFFFFF" expand="lg" style={{ padding: 0, margin: 0 }}>
        <Container>
          <div className="d-flex flex-grow-1">
            <Navbar.Brand>
              <Button variant="link" onClick={onNavigateHome}>
                <div style={{ display: 'inline-block', float: 'left' }}>
                  <img
                    src={logo}
                    width="534"
                    height="140"
                    className="d-lg-inline-block align-top"
                    alt="Master Manufacturing Group"
                  />
                </div>
              </Button>
            </Navbar.Brand>
          </div>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="text-right flex-column flex-grow-1 align-items-end"
          >
            <div
              className="align-items-center d-none d-lg-flex mr-2 mt-4"
              style={{ color: '#C01127' }}
            >
              <a href="mailto:frontdesk@mastermfggroup.com" style={{ color: '#C01127' }}>
                frontdesk@mastermfggroup.com
              </a>
              &nbsp;|&nbsp;
              {/* eslint-disable-next-line */}
              <a href="tel:+14236672582" style={{ color: '#C01127' }}>
                423.428.7810
              </a>
              &nbsp;|&nbsp;
              {/* eslint-disable-next-line */}
              <a
                onClick={onNavigateQuote}
                href="#"
                style={{ color: '#C01127', fontWeight: 'bold' }}
              >
                Request a Quote
              </a>
            </div>
            <Nav onSelect={onNavLinkNavigate} className="ml-auto flex-nowrap">
              <Nav.Link href={`#${SiteViews.About}`}>
                About
              </Nav.Link>
              <Nav.Link href={`#${SiteViews.Fabrication}`}>
                Fabrication
              </Nav.Link>
              <Nav.Link href={`#${SiteViews.Engineering}`}>
                Engineering
              </Nav.Link>              
              <NavDropdown title="Portfolio" id="collasible-nav-dropdown">
                <NavDropdown.Item href={`#${SiteViews.Portfolio}`}>
                  Project List
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={`#${SiteViews.MetalFormingStainlessSteelTube}`}
                >
                  Ring Burner
                  <br />
                  Industrial Cooking
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={`#${SiteViews.CustomFabricationStainlessSteelBurner}`}
                >
                  Steel Burner
                  <br />
                  Outdoor Grilling
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={`#${SiteViews.PrecisionFabricationYShapedStainlessSteelBurner}`}
                >
                  Y Shaped Burner
                  <br />
                  Residential Fireplace
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={`#${SiteViews.CustomFabricatedDoubleWallManifold}`}
                >
                  Double Walled Manifold
                  <br />
                  Commercial Boiler
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={`#${SiteViews.MetalFabricationCommercialBurner}`}
                >
                  U Shaped Burner
                  <br />
                  Commercial Cooking
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={`#${SiteViews.CustomFabricatedAluminumFireplaceConnectorTube}`}
                >
                  Aluminum Connecture Tube
                  <br />
                  Residential Fireplace
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href={`#${SiteViews.Team}`}>
                Team
              </Nav.Link>
              <Nav.Link href={`#${SiteViews.Contact}`}>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
