import React from 'react';

const MMGMap = () => {
  return (
    <div style={{ marginRight: 0 }}>
      <iframe 
        title="Master Manufacturing Group Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3249.555606646986!2d-85.03358558505212!3d35.4657947493649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8860a354e1768503%3A0xe07cb4e410f09ee7!2sMaster%20Manufacturing%20Group%2C%20Dayton%2C%20TN%2037321!5e0!3m2!1sen!2sus!4v1611592311819!5m2!1sen!2sus" 
        width="100%" 
        height="450" 
        style={{ border: 0 }}
        ></iframe>
    </div>
  );
};
export default MMGMap;
