import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { SiteViews } from '../../models/enums/siteViews';
import onNavigate from '../../modules/onNavigate';

import accentImage from '../../images/metal_img.jpg';

const RingBurner: React.FC = () => {
  let navigate = useNavigate();

  return (
    <Container className="page-container">
      <Row>
        <Col xs={12}>
          <h3>Fabrication of a Stainless Steel Ring Burner for the Industrial Cooking Industry</h3>
          <p>
            <img
              src={accentImage}
              alt="Steel Ring Burner"
              style={{ marginRight: 12 }}
              className="float-start img-fluid"
            />
            This project highlights Master Manufacturing Group's ability to design and engineer
            innovative solutions utilizing multiple fabricating processes. Designed for use in an
            industrial cooking application, this ring burner featured a two ring design developed by
            Master Manufacturing Group. It also demonstrates our ability to combine multiple pieces (4 body & 3
            brackets) into a single product. All of the burner components are composed of 1.25"
            O.D., 0.035" thick, 302 stainless steel tubing.
          </p>
          <p>
            Manufacturing began with the shearing of the raw stainless tubing; the sections were
            then ported using a proprietary CNC porting machine. Fabrication also utilized our
            automatic plasma cutter, a CNC bender, as well as a punch press and MIG welding to join
            all of the components. Dies and tooling are all manufactured in-house which gives us
            greater control over quality and process. Other features of the burner were the
            application of a T notch as well as end and venturi smash. The finished octagonal burner
            ring featured an outside diameter of 15", with the ports meeting tolerances ±0.003".
          </p>
          <p>
            To learn more about this project or the processes Master Manufacturing Group used to manufacture it, see
            the table below or&nbsp;
            <span className="fake-link" onClick={() => navigate(onNavigate(`#${SiteViews.Contact}`))}>
              contact us
            </span>
            &nbsp;directly.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant="primary" onClick={() => navigate(onNavigate(`#${SiteViews.Portfolio}`))}>
            View Other Portfolio
          </Button>
        </Col>
        <Col>
          <Button
            variant="primary"
            className="float-end"
            onClick={() => navigate(onNavigate(`#${SiteViews.Quote}`))}
          >
            Request Quote
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4 className="project-header">Project Highlights:</h4>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p>
            <strong>Product Description</strong> Ring burner is used on industrial cooking equipment
            and has an octagonal shape.
          </p>
          <div style={{ paddingBottom: '1rem' }}>
            <div>
              <strong>Precision Metal Forming Capabilities Applied/Processes</strong>
            </div>
            <div className="float-end" style={{ paddingRight: 36 }}>
              Secondary: <br />
              End Smash <br />
              T-Notch <br />
              Venturi Smash
            </div>
            <div>
              Primary: <br />
              Design <br />
              Engineering/Product Development <br />
              Shearing <br />
              Porting <br />
              Plasma Cutting <br />
              Bending <br />
              Welding - MIG <br />
              Stamping
            </div>
          </div>
          <p>
            <strong>Equipment Used to Manufacture Part</strong> Tube Shear, Proprietary CNC Porting
            Machines, Automatic Plasma Cutter, CNC Bender, Punch Press, MIG Welder, Machine Shop
            (fabricated dies)
          </p>
        </Col>
        <Col md={6}>
          <p>
            <strong>Overall Part Dimensions</strong> O.D.: Ø 15"
          </p>
          <p>
            <strong>Tightest Tolerances</strong> ± 0.003" on Ports
          </p>
          <p>
            <strong>Material Used</strong> 304 Stainless Steel Tubing O.D.: Ø 1.25" Thickness:
            0.035"
          </p>
          <p>
            <strong>Material Finish</strong> Raw/Machined
          </p>
          <p>
            <strong>Industry for Use</strong> Commercial Cooking Industry
          </p>
          <p>
            <strong>Volume</strong> Low Production
          </p>
          <p>
            <strong>Delivery Location</strong> Nationwide
          </p>
          <p>
            <strong>Standards Met</strong> Customer Specifications, 2D CAD Drawing
          </p>
          <p>
            <strong>Product Name</strong> Octagonal Ring Burner
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default RingBurner;
