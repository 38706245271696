import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';

import building from '../../images/mmg-building.jpg';

const About : React.FC = () => {

    return (
        <Container className="page-container">
            <Row>
                <Col xs={12}>
                        <h3>About Master Manufacturing Group</h3>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img className="img-fluid" src={building} alt="Master Manufacturing Group" />
                        </div>
<p>Master Manufacturing Group (“MMG”) is a custom job shop with primary experience utilizing tubing to satisfy customer applications in the gas appliance space.  Our capabilities extend beyond tubing, and beyond the gas appliance industry.  Challenge us to use our creative engineering resources to bring cost-effective and targeted solutions helping you succeed.</p>
<p>MMG started in the San Jose, CA area in 1956 as Woodmack Products Inc.   The company moved production to Rancho Cordova, CA in the late 1990s.  Finally, the company moved operations to Dayton, TN in the summer of 2020.</p>
<p>We are now located in the hills of East Tennessee, having access to a quality workforce of human capital.  The area has a high concentration of people skilled in manufacturing, supported by local training programs focused on technical trade skills, such as electronics, CNC programming, PLC programming and welding technology.</p>
<p>As a privately held manufacturing company with over 60 years of experience, we provide generations of uninterrupted personal service to our clients. We are housed in a 125,000 sq. ft. production facility on twenty acres and we are ready to assist you with all your production needs. </p>
<p>MMG has developed a reputation of being a responsive, creative and quality-oriented supplier. By working closely together with our customers, we are able to establish a partnership that allows us to become an extension of your company.</p>
                </Col>
            </Row>
        </Container>
        
    );

}

export default About;
