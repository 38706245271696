import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import accentImage from '../../images/desing_img.jpg';

const Engineering: React.FC = () => {
  return (
    <Container className="page-container">
      <Row>
        <Col xs={12}>
          <h3>Design &amp; Engineering Services</h3>
          <p className="text-center project-header">
            <span style={{ fontStyle: 'italic' }}>
              "We have the experience, so you don't have to."
            </span>
          </p>
          <div
            className="mb-4"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <img src={accentImage} alt="Design Blueprint" />
          </div>
<p>"Production Design" emphasizes the specialized support behind the scenes that keeps the production floor running. This is accomplished first by having a complete in-house tool and die room with experienced machinists on staff. This allows us to make and maintain all dies and tooling on site, minimizing production down time when failures occur. It also allows us to quickly prepare for new products, since we are never waiting on tooling to arrive, and augment tooling as necessary for design changes.</p>
<p>In addition to in-house tooling, we have an experienced engineering staff to help take an idea to the production floor. Much of the production equipment is proprietary, designed and constructed in-house to increase production efficiency and expand manufacturing capability. We can review designs and recommend changes to increase manufacturability while retaining the efficacy of the original part design. This translates to higher quality, faster lead times, and less expense for all our customers.</p>
<p>Approach us with an idea, and we can tell you how to get your idea made, approach us with a design, and we can tell you how to make it better.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Engineering;
