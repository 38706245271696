import emailjs from 'emailjs-com';
import React from 'react';
import { Alert, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import useForm from '../../modules/useForm';
import quoteFormValidation from './quoteFormValidation';

const RequestQuote: React.FC = () => {
  const sendForm = async () => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    if (values.noopHoney) {
      // Honey pot tripped
      return;
    }

    if (!isSending) {
      return;
    }

    var service_id = 'default_service';
    var template_id = 'woodmack_quote_form';
    var user_id = 'user_icYm8ZYSHLY7bspG0MT8f';

    try {
      await emailjs.send(service_id, template_id, values, user_id);
      setHasError(false);
    } catch (error) {
      console.log(error);
      setHasError(true);
    } finally {
      setIsSubmitting(false);
      setIsSubmitted(true);
    }
  };

  const {
    values,
    errors,
    handleChange,
    handleCheckboxChange,
    handleSubmit,
    setIsSubmitting,
    isSending,
    isSubmitted,
    setIsSubmitted,
    hasError,
    setHasError,
  } = useForm(sendForm, quoteFormValidation);

  return (
    <Container className="page-container">
      <Row>
        <Col xs={12}>
          <h3>Request a Quote</h3>
        </Col>
      </Row>
      <Form validated={Object.keys(errors).length > 0}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formName">
              <Form.Label>Name*</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={values.name || ''}
                required={true}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formCompany">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="company"
                value={values.company || ''}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formJobTitle">
              <Form.Label>Job Title</Form.Label>
              <Form.Control
                type="text"
                name="jobTitle"
                value={values.jobTitle || ''}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email*</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={values.email || ''}
                required={true}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formPhone">
              <Form.Label>Phone*</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={values.phone || ''}
                required={true}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formFax">
              <Form.Label>Fax</Form.Label>
              <Form.Control
                type="text"
                name="fax"
                value={values.fax || ''}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={values.address || ''}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formCity">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={values.city || ''}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formState">
              <Form.Label>State Or Province</Form.Label>
              <Form.Control
                type="text"
                name="stateOrProvince"
                value={values.stateOrProvince || ''}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formPostalCode">
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                type="text"
                name="postalCode"
                value={values.postalCode || ''}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formCountry">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="text"
                name="country"
                value={values.country || ''}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Which Applications are you interested in?</Form.Label>
              <Form.Check
                label="Tube and Piping Solutions"
                name="appTubePiping"
                value={values.contactEmail || false}
                onChange={handleCheckboxChange}
              ></Form.Check>
              <Form.Check
                label="Design and Engineering Services"
                name="appDesignEngineering"
                value={values.contactEmail || false}
                onChange={handleCheckboxChange}
              ></Form.Check>
            </Form.Group>
            <Form.Group>
              <Form.Label>How would you like to be contacted?</Form.Label>
              <Form.Check
                label="Email"
                name="contactEmail"
                value={values.contactEmail || false}
                onChange={handleCheckboxChange}
              ></Form.Check>
              <Form.Check
                label="Mail"
                name="contactMail"
                value={values.contactMail || false}
                onChange={handleCheckboxChange}
              ></Form.Check>
              <Form.Check
                label="Fax"
                name="contactFax"
                value={values.contactFax || false}
                onChange={handleCheckboxChange}
              ></Form.Check>
              <Form.Check
                label="Phone"
                name="contactPhone"
                value={values.contactPhone || false}
                onChange={handleCheckboxChange}
              ></Form.Check>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formDetails">
              <Form.Label>Additional Details</Form.Label>
              <Form.Control
                as="textarea"
                rows={7}
                name="details"
                value={values.details || ''}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <input
              id="noopHoney"
              name="noopHoney"
              type="text"
              onChange={handleChange}
              style={{ display: 'none' }}
            />
            {!isSending && !isSubmitted && (
              <Button
                variant="primary"
                className="float-end"
                onClick={handleSubmit}
                disabled={isSending}
              >
                Send
              </Button>
            )}
            {isSending && !isSubmitted && <Spinner animation="grow" className="float-end" />}
          </Col>
        </Row>
        {isSubmitted && hasError && (
          <Row style={{ height: '92%' }}>
            <Col>
              <Alert variant="danger" style={{ height: '100%' }}>
                There was a problem sending your information - please try again shortly!
              </Alert>
            </Col>
          </Row>
        )}
        {isSubmitted && !hasError && (
          <Row style={{ height: '92%' }}>
            <Col>
              <Alert variant="success" style={{ height: '100%' }}>
                Your information has been submitted. We'll be in touch very shortly to follow up!
              </Alert>
            </Col>
          </Row>
        )}
      </Form>
    </Container>
  );
};

export default RequestQuote;
