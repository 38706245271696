import React from 'react';
import { Routes, Route  } from 'react-router-dom';
import Footer from '../components/footer';
import Header from '../components/header';
import About from '../containers/About';
import Applications from '../containers/Applications';
import Contact from '../containers/Contact';
import Engineering from '../containers/Engineering';
import Fabrication from '../containers/Fabrication';
import Home from '../containers/Home';
import Portfolio from '../containers/Portfolio';
import Team from '../containers/Team';
import AluminumConnectureTube from '../containers/Portfolio/aluminumConnectureTube';
import DoubleWalledManifold from '../containers/Portfolio/doubleWalledManifold';
import RingBurner from '../containers/Portfolio/ringBurner';
import SteelBurner from '../containers/Portfolio/steelBurner';
import UShapedBurner from '../containers/Portfolio/uShapedBurner';
import YShapedBurner from '../containers/Portfolio/yShapedBurner';
import PrivacyPolicy from '../containers/PrivacyPolicy';
import RequestQuote from '../containers/Quote';

const App: React.FC = () => {
  return (
    <div id="wm-root">
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/fabrication' element={<Fabrication />} />
        <Route path='/engineering' element={<Engineering />} />
        <Route path='/applications' element={<Applications />} />
        <Route path='/portfolio' element={<Portfolio />} />
        <Route path='/team' element={<Team />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/quote' element={<RequestQuote />} />
        <Route path='/metal-forming-stainless-steel-tube' element={<RingBurner />} />
        <Route path='/custom-fabrication-stainless-steel-burner' element={<SteelBurner />} />
        <Route path='/precision-fabrication-y-shaped-stainless-steel-burner' element={<YShapedBurner />} />
        <Route path='/custom-fabricated-double-wall-manifold' element={<DoubleWalledManifold />} />
        <Route path='/metal-fabrication-commercial-burner' element={<UShapedBurner />} />
        <Route path='/custom-fabricated-aluminum-fireplace-connector-tube' element={<AluminumConnectureTube />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
