const onNavigate = (siteView: string): string => {
    switch (siteView) {
        case '#home':
            return '/';
        default:
            const target = siteView.replace('#', '/');
            return target;
    }
}

export default onNavigate;
