import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { SiteViews } from '../models/enums/siteViews';

const Footer: React.FC = () => {
    let navigate = useNavigate();

    const onNavigatePrivacy = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        navigate(SiteViews.PrivacyPolicy);
    }

    return (
        <footer style={{marginTop: 12}}>
            <Container>
                <Row>
                    <Col>
                        <div style={{paddingTop: 8, paddingLeft: 24}}>
                            <address>
                                <strong>MASTER MANUFACTURING GROUP</strong>
                                <br />
                                220 Masters Way
                                <br />
                                Dayton, TN  37321
                                <br />
                                Office: 423.428.7810<br />
                                <a href="mailto:frontdesk@mastermfggroup.com">frontdesk@mastermfggroup.com.</a>
                            </address>
                        </div>
                    </Col>
                    <Col className="d-flex align-items-end justify-content-end">
                        <div className="text-right" style={{paddingBottom: 18, paddingRight: 24, bottom: 0}}>
                            <div>
                                {/* eslint-disable-next-line */}
                                <a onClick={onNavigatePrivacy} href="#">Privacy Policy</a><br />
                                Site Built and Designed by<br /><a href="https://terminalvelocitylabs.com">Terminal Velocity Labs</a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );

}

export default Footer;
